import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'homework',
    loadChildren: () => import('./homework/homework.module').then( m => m.HomeworkPageModule)
  },
  {
    path: 'manage-homework',
    children:[
      {
        path:'',
        loadChildren: () => import('./manage-homework/manage-homework.module').then( m => m.ManageHomeworkPageModule)
      },
      {
        path:':homeworkId',
        loadChildren: () => import('./manage-homework/manage-homework.module').then( m => m.ManageHomeworkPageModule)
      },

    ]
    
  },
  {
    path: 'atendance',
    loadChildren: () => import('./atendance/atendance.module').then( m => m.AtendancePageModule)
  },
  {
    path: 'new-dashboard',
    loadChildren: () => import('./newdashboard/newdashboard.module').then( m => m.NewdashboardPageModule)
  },
  {
    path: 'event',
    loadChildren: () => import('./event/event.module').then( m => m.EventPageModule)
  },
  {
    path: 'manage-event',
    children:[
      {
        path:'',
        loadChildren: () => import('./manage-event/manage-event.module').then( m => m.ManageEventPageModule)
      },
      {
        path:':id',
        loadChildren: () => import('./manage-event/manage-event.module').then( m => m.ManageEventPageModule)
      },
    ]
  },
  {
    path: 'announcement',
    loadChildren: () => import('./announcement/announcement.module').then( m => m.AnnouncementPageModule)
  },
  {
    path: 'manage-announcement',
    children:[
      {
        path:'',
        loadChildren: () => import('./manage-announcement/manage-announcement.module').then( m => m.ManageAnnouncementPageModule)
      },
      {
        path:':id',
        loadChildren: () => import('./manage-announcement/manage-announcement.module').then( m => m.ManageAnnouncementPageModule)
      }
    ]
  },
  {
    path: 'student-list',
    loadChildren: () => import('./student-list/student-list.module').then( m => m.StudentListPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'mobile',
    loadChildren: () => import('./mobile/mobile.module').then( m => m.MobilePageModule)
  },
  {
    path: 'mobile-verify',
    loadChildren: () => import('./mobile-verify/mobile-verify.module').then( m => m.MobileVerifyPageModule)
  },
  {
    path: 'choose-class',
    loadChildren: () => import('./choose-class/choose-class.module').then( m => m.ChooseClassPageModule)
  },
  {
    path: 'leave-form',
    loadChildren: () => import('./leave-form/leave-form.module').then( m => m.LeaveFormPageModule)
  },
  {
    path: 'leave-status',
    loadChildren: () => import('./leavestatus/leavestatus.module').then( m => m.LeavestatusPageModule)
  },
  {
    path: 'manage-leave',
    loadChildren: () => import('./manage-leave/manage-leave.module').then( m => m.ManageLeavePageModule)
  },
  {
    path: 'attendance-report',
    loadChildren: () => import('./attendance-report/attendance-report.module').then( m => m.AttendanceReportPageModule)
  },
  {
    path: 'homework-report',
    loadChildren: () => import('./homework-report/homework-report.module').then( m => m.HomeworkReportPageModule)
  },
  {
    path: 'timetable',
    children:[
      {
        path:'',
        loadChildren: () => import('./timetable/timetable.module').then( m => m.TimetablePageModule)
      },
      {
        path:':id',
        loadChildren: () => import('./timetable/timetable.module').then( m => m.TimetablePageModule)
      }
    ]
  },
  {
    path: 'manage-timetable',
    loadChildren: () => import('./manage-timetable/manage-timetable.module').then( m => m.ManageTimetablePageModule)
  },
  {
    path: 'calendar',
    loadChildren: () => import('./calendar/calendar.module').then( m => m.CalendarPageModule)
  },
  {
    path: 'manage-calendar',
    loadChildren: () => import('./manage-calendar/manage-calendar.module').then( m => m.ManageCalendarPageModule)
  },
  {
    path: 'edit-calendar-event',
    children:[
      {
        path:'',
        loadChildren: () => import('./edit-calendar-event/edit-calendar-event.module').then( m => m.EditCalendarEventPageModule)
      },
      {
        path:':id',
        loadChildren: () => import('./edit-calendar-event/edit-calendar-event.module').then( m => m.EditCalendarEventPageModule)
      }
    ]
  },
  {
    path: 'messages',
    children:[
      {
        path:':user',
        loadChildren: () => import('./messages/messages.module').then( m => m.MessagesPageModule)
      }
    ]
  },
  {
    path: 'manage-activity',
    children:[
      {
        path:'',
        loadChildren: () => import('./manage-activity/manage-activity.module').then( m => m.ManageActivityPageModule)
      },
      {
        path:':id',
        loadChildren: () => import('./manage-activity/manage-activity.module').then( m => m.ManageActivityPageModule)
      }
    ]
  },
  {
    path: 'manage-standard',
    children:[
      {
        path:'',
        loadChildren: () => import('./add-class/add-class.module').then( m => m.AddClassPageModule)
      },
      {
        path:':id',
        loadChildren: () => import('./add-class/add-class.module').then( m => m.AddClassPageModule)
      }
    ]
  },
  {
    path: 'activity',
    loadChildren: () => import('./activity/activity.module').then( m => m.ActivityPageModule)
  },
  {
    path: 'standards',
    loadChildren: () => import('./standards/standards.module').then( m => m.StandardsPageModule)
  },
  {
    path: 'send-message',
    children:[
      {
        path:':chat_id',
        loadChildren: () => import('./send-message/send-message.module').then( m => m.SendMessagePageModule)
      }
    ]
  },
  {
    path: 'newdashboard',
    loadChildren: () => import('./newdashboard/newdashboard.module').then( m => m.NewdashboardPageModule)
  },
  {
    path: 'leavestatus',
    loadChildren: () => import('./leavestatus/leavestatus.module').then( m => m.LeavestatusPageModule)
  },
  {
    path: 'register-check',
    loadChildren: () => import('./registercheck/registercheck.module').then( m => m.RegistercheckPageModule)
  },
  {
    path: 'forgotpassword',
    loadChildren: () => import('./forgotpassword/forgotpassword.module').then( m => m.ForgotpasswordPageModule)
  },
  {
    path: 'add-class',
    loadChildren: () => import('./add-class/add-class.module').then( m => m.AddClassPageModule)
  },
  {
    path: 'add-subject',
    loadChildren: () => import('./add-subject/add-subject.module').then( m => m.AddSubjectPageModule)
  },
  {
    path: 'standards',
    loadChildren: () => import('./standards/standards.module').then( m => m.StandardsPageModule)
  },
  {
    path: 'subjects',
    loadChildren: () => import('./subjects/subjects.module').then( m => m.SubjectsPageModule)
  },
  {
    path: 'manage-subject',
    children:[
      {
        path:'',
        loadChildren: () => import('./add-subject/add-subject.module').then( m => m.AddSubjectPageModule)
      },
      {
        path:':id',
        loadChildren: () => import('./add-subject/add-subject.module').then( m => m.AddSubjectPageModule)
      }
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
  
})
export class AppRoutingModule { }
