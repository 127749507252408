import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastControl:ToastController) { }

  async successToast(toast_message)
  {
    const success_toast= await this.toastControl.create({
      message:toast_message,
      position:"top",
      color:"success",
      duration:2000
    });
    success_toast.present();
  }

  async warningToast(toast_message)
  {
    const no_data_toast= await this.toastControl.create({
      message:toast_message,
      position:"top",
      color:"warning",
      duration:2000
    });
    no_data_toast.present();
  }

  async errorToast(toast_message)
  {
    const error_toast= await this.toastControl.create({
      message:toast_message,
      position:"top",
      color:"danger",
      duration:2000
    });
    error_toast.present();
  }
}
