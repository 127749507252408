import { Component } from '@angular/core';

import { IonRouterOutlet, MenuController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router, RouterOutlet } from '@angular/router';
import { Storage } from '@ionic/storage'
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { ToastService } from './services/toast.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private menu: MenuController,
    private router:Router,
    private storage:Storage,
    private toastService: ToastService
  ) 

  {
    try{
      this.initializeApp();
    
    }
    catch(ex)
    {
      this.toastService.errorToast(ex);
    }
    
  }
  userType:string 
  toggleableMenus=[
    {
      menu_name:"report",state:false
    },
    {
      menu_name:"leave",state:false
    },
    {
      menu_name:"timetable",state:false
    },
    {
      menu_name:"calendar", state:false
    },
    {
      menu_name: "messages", state:false
    },
    {
      menu_name: "master", state:false
    },
  ]
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }
  toggleMenu(menu_name)
  {
    this.toggleableMenus.find(x=>x.menu_name==menu_name).state=
    this.toggleableMenus.find(x=>x.menu_name==menu_name).state==false?true:false;
    this.collapse(menu_name);
  }
  collapse(menu_name)
  {
    this.toggleableMenus.forEach(element => {
     if(element.menu_name!=menu_name)
          element.state=false;
  });
  }
  getIcon(menu)
  {
    if(this.toggleableMenus.find(x=>x.menu_name==menu).state==true)
          return 'chevron-up-outline' ;
   return 'chevron-down-outline';
  }
  isHidden(menu)
  {
    if(this.toggleableMenus.find(x=>x.menu_name==menu).state==true)
      return false;
    return true;
  }
  toggle()
  {
    if(this.menu.isOpen())
    {
      this.menu.toggle(); 
    }
    this.toggleAllMenus();
  }

  toggleAllMenus()
  {
    this.toggleableMenus.forEach(element => {
        element.state=false;  
    });
    
  }

  logout()
  {
    this.toggle();
    this.storage.remove("user");
    this.router.navigate(["/login"]);
  }
}